//Transaction Fields
const transaction = [];
transaction.push({ key: 1, value: 'transaction_id', text: 'Id de la transacción ' });
transaction.push({ key: 2, value: 'customer_name', text: 'Nombre del cliente' });
transaction.push({ key: 3, value: 'credit_card_id', text: 'Id de la tarjeta' });
transaction.push({ key: 4, value: 'credit_card_number', text: 'Número de tarjeta (Utiliza máscara de seguridad)' });
transaction.push({ key: 5, value: 'invoice', text: 'Número de factura' });
transaction.push({ key: 6, value: 'authorization_number', text: 'Número de autorización ' });
transaction.push({ key: 7, value: 'merchant_description', text: 'Nombre de Estación de Servicio' });
transaction.push({ key: 8, value: 'system_trace_code', text: 'Valor único generado por la terminal' });
transaction.push({ key: 9, value: 'holder_name', text: 'Tarjetahabiente' });
transaction.push({ key: 10, value: 'date', text: 'Fecha de transacción' });
transaction.push({ key: 11, value: 'fuel_name', text: 'Nombre combustible' });
transaction.push({ key: 12, value: 'fuel_amount', text: 'Indica el monto de la transacción en base a la moneda con la que trabaja o se configura el cliente. Cuando la transacción es internacional o la moneda del cliente es diferente a la del país donde se realiza la transacción, este monto se calcula en base al tipo de cambio que se tiene en el momento de la transacción' });
transaction.push({ key: 13, value: 'odometer', text: 'Odómetro del vehículo' });
transaction.push({ key: 14, value: 'liters', text: 'Número de litros' });
transaction.push({ key: 15, value: 'plate_id', text: 'Placa de vehículo' });
transaction.push({ key: 16, value: 'currency_symbol', text: 'Símbolo de moneda' });
transaction.push({ key: 17, value: 'state', text: 'Estado de la transacción' });
transaction.push({ key: 18, value: 'cost_center_name', text: 'Nombre de Centro de Costo' });
transaction.push({ key: 19, value: 'terminal_id', text: 'Terminal donde se realiza la transacción' });
transaction.push({ key: 20, value: 'vehicle_name', text: 'Nombre del vehículo' });
transaction.push({ key: 21, value: 'country_name', text: 'País donde se realiza la transacción' });
transaction.push({ key: 22, value: 'exchange_value', text: 'Tipo de cambio ' });
transaction.push({ key: 23, value: 'real_amount', text: 'Es el monto asociado a la moneda del país donde se realiza la transacción, este sería diferente a "fuel_amount" si el cliente tiene una moneda diferente a la del país, o si la transacción es internacional.' });
transaction.push({ key: 24, value: 'driver_name', text: 'Nombre de conductor' });
transaction.push({ key: 25, value: 'is_international', text: 'Indica si la transacción es internacional' });
transaction.push({ key: 26, value: 'filter_type', text: 'Estado de la Transacción' });
transaction.push({ key: 27, value: 'unit_of_capacity_id', text: 'Indica si el cliente utiliza galones o litros' });
transaction.push({ key: 28, value: 'message', text: 'Indica el motivo por el cual la transacción ha sido denegada. Este mensaje es mostrado solo para transacciones denegadas' });
transaction.push({ key: 29, value: 'rows', text: 'Indica el número de resultados en la consulta' });
transaction.push({ key: 30, value: 'pages', text: 'Indica la página mostrada' });

//transaction values to request
const transactionRequest = [];
transactionRequest.push({ key: 1, value: 'Token Actual', text: 'Código seguro, al no indicar este se obtiene en el resultado datos de prueba. *' });
transactionRequest.push({ key: 2, value: 'Fecha Inicial', text: 'A partir de donde se filtran las transacciones. *' });
transactionRequest.push({ key: 3, value: 'Fecha Final', text: 'Hasta donde se filtran las transacciones. *' });
transactionRequest.push({
    key: 4, value: 'Estado', text: 'Indica el tipo de transacciones que se desea obtener.\n\tAl no indicar el estado se mostraran todas las transacciones.\n\t' +
        '1 - Procesadas\n\t2 - Flotantes\n\t3 - Reversadas\n\t6 - Denegadas\n\t7 - Offline\n\t9 - Anuladas. *'
});
transactionRequest.push({ key: 5, value: 'Filas', text: 'Número de transacciones por solicitud. Valor por defecto: 500.' });
transactionRequest.push({ key: 6, value: 'Página', text: 'Página. Valor por defecto: 1.' });
transactionRequest.push({ key: 7, value: 'Id Última Transacción', text: 'Indica cuál fue la última transacción solicitada anteriormente, las transacciones en la nueva solicitud será a partir de este Id.' });
transactionRequest.push({ key: 7, value: '*', text: 'Valor Requerido.' });

//Error codes
const errorCodes = [];
errorCodes.push({ key: 1, value: '400', text: 'Solicitud incorrecta: Se ha intentado una solicitud no válida.' });
errorCodes.push({ key: 2, value: '401', text: 'No autorizado: No se ha utilizado el Código Seguro.' });
errorCodes.push({ key: 3, value: '404', text: 'No encontrado: No se pudo encontrar el recurso especificado.' });
errorCodes.push({ key: 4, value: '500', text: 'Error interno del servidor: Ocurrió un problema con el servidor. Inténtalo de nuevo más tarde.' });

//Service Station
const serviceStation = [];
serviceStation.push({ key: 1, value: 'service_station_id', text: 'Id de la Estación de Servicio en Flotas ' });
serviceStation.push({ key: 2, value: 'bac_id', text: 'Id de la Estación de Servicio en BAC' });
serviceStation.push({ key: 3, value: 'name', text: 'Nombre de la Estación de Servicio' });
serviceStation.push({ key: 4, value: 'address', text: 'Dirección' });
serviceStation.push({ key: 5, value: 'county_id', text: 'Id Cantón' });
serviceStation.push({ key: 6, value: 'state_id', text: 'Id Provincia' });
serviceStation.push({ key: 7, value: 'latitude', text: 'Latitud' });
serviceStation.push({ key: 8, value: 'longitude', text: 'Longitud' });
serviceStation.push({ key: 9, value: 'sap_prov', text: 'Número para Sistemas SAP' });
serviceStation.push({ key: 10, value: 'legal_id', text: 'Cedula jurídica' });
serviceStation.push({ key: 11, value: 'terminal', text: 'Número de Terminal Principal ' });
serviceStation.push({ key: 12, value: 'is_blocked', text: 'Indica si la estación se encuentra bloqueada en el sistema' });
serviceStation.push({ key: 13, value: 'cost_center_blocked', text: 'Indica si la estación se encuentra bloqueada en el sistema para los centros de costo' });
serviceStation.push({ key: 14, value: 'top_monetary_limit', text: 'Monto máximo que se puede consumir en la Estación de Servicio' });
serviceStation.push({ key: 15, value: 'number_provider', text: 'Número de proveedor' });

const serviceStationRequest = [];
serviceStationRequest.push({ key: 1, value: 'Token Actual', text: 'Código seguro' });

export default class arrays {
    static transaction = transaction;
    static serviceStation = serviceStation;
    static transactionRequest = transactionRequest;
    static serviceStationRequest = serviceStationRequest;
    static errorCodes = errorCodes;
}
