import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIcon from '@material-ui/icons/Assignment';

export const mainListItems = (
  <div>
    <ListItem button onClick={() => GoToElementInView('introduction')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Introducción" />
    </ListItem>
    <ListItem button onClick={() => GoToElementInView('overview')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Visión general" />
    </ListItem>
    <ListItem button onClick={() => GoToElementInView('authentication')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Autenticación" />
    </ListItem>
    <ListItem button onClick={() => GoToElementInView('errorcodes')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Códigos de Error" />
    </ListItem>
    {/* Temp Remove from web site, pending validate in Azure
     <ListItem button onClick={() => GoToElementInView('ratelimit')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Límite de Solicitudes" />
    </ListItem> */}
  </div>
);

//Scroll to the element 
const GoToElementInView = function (elementToGo) {
  const element = document.getElementById(elementToGo);
  element.scrollIntoView({ behavior: "smooth", offsetTop: -1000, block: "start" });
}