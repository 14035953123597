require('dotenv').config();

const localUrl = process.env['REACT_APP_SITE_URL'];
const sample = '\\"';

//Transaction transcontent
const transcontent = 'application/x-www-form-urlencoded';
const transbody = '{\n\t"startdate": "",\n\t"enddate": "",\n\t"status": 1,\n\t"rows": 1,\n\t"page": 1,\n\t"lasttransactionid": null\n}';
const transactiontranscontent = [];
transactiontranscontent.push({
    key: 1, label: "curl", value: 0, text: '--location --request POST "' + localUrl + '/transaction"\n' +
        '--header "content-Type: ' + transcontent + '"\n--header "Authorization: "\n--data "' + transbody + '"'
});
transactiontranscontent.push({
    key: 2, label: "jQuery", value: 1, text: 'var settings = {\n\t"url": ' + localUrl + '/transaction, \n\t"method": "POST", \n\t"timeout": 0, \n\t"headers": { \n\t\t"content-Type": "application/x-www-form-urlencoded",\n\t\t"Authorization": ""\n\t}, \n\t' +
        '"data": {\n\t\t"startdate": "",\n\t\t"enddate": "",\n\t\t"status": 1,\n\t\t"rows": 1,\n\t\t"page": 1,\n\t\t"lasttransactionid": null\n\t} \n};\n $.ajax(settings).done(function (response) { \n\tconsole.log(response); \n});'
});
// transactiontranscontent.push({
//     key: 3, label: "Node", value: 2, text: "var http = require('http'); \nvar qs = require('querystring');\nvar options = {\n\t'method': 'POST',\n\t'hostname': 'localhost'," +
//         "\n\t'path': '/v1/transaction',\n\t'headers': {\n\t\t'content-Type': '" + transcontent + "',\n\t\t'Authorization': ''\n\t}\n};" +
//         "\nvar req = http.request(options, function (res) {\n\tvar chunks = [];\n\tres.on(\"data\", function (chunk) {\n\t\tchunks.push(chunk);\n\t});" +
//         "\n\tres.on(\"end\", function (chunk) {\n\t\tvar body = Buffer.concat(chunks);\n\t\tconsole.log(body.toString());\n\t});" +
//         "\n\tres.on(\"error\", function (error) {\n\t\tconsole.error(error);\n\t});\n});" +
//         "\nvar postData = qs.stringify(" + transbody.replace(/"/g, "'") + ");\nreq.write(postData);\nreq.end();"
// });

transactiontranscontent.push({
    key: 3, label: "Node", value: 2, text: "const rp = require('request-promise');" +
    "\nlet data = \"{\\n\\t\\\"startdate\":\"\",\\n\\t\\\"enddate\": \"\",\\n\\t\\\"status\": 1,\\n\\t\\\"rows\": 1,\\n\\t\\\"page\": 1,\\n\\t\\\"lasttransactionid\": null\\n}\";"+
    "\nlet options = { "+
    "\n\tmethod: 'POST', "+
    "\n\turi: ' " + localUrl + "/transaction',"+
    "\n\tbody: JSON.stringify(data),"+
    "\n\theaders: {"+
    "\n\t\t'content-type': 'application/json',"+
    "\n\t\t'Authorization': ''"+
    "\n\t},"+
    "\n}"+
    "\nrp(options)"+
    "\n\t.then(function (response) {"+
    "\n\t\tconsole.log(response);"+
    "\n\t}).catch(function (error) {"+
    "\n\t\tconsole.log(error);"+
    "\n});"
});

transactiontranscontent.push({
    key: 4, label: "Ruby", value: 3, text: 'require "uri"\nrequire "net/http"\nurl = URI("' + localUrl + '/transaction")\nhttp = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nrequest = Net::HTTP::Post.new(url)\n' +
        'request["content-Type"] = " ' + transcontent + ' "\nrequest["Authorization"] = ""\nrequest.body = "' + transbody.replace(/"/g, `${sample}`) + '"\nresponse = http.request(request)\nputs response.read_body'
});
transactiontranscontent.push({
    key: 5, label: "Python", value: 4, text: "import requests\nurl = '" + localUrl + "/transaction'\npayload = '" + transbody.replace(/"/g, "'") + "'\n" +
        "headers = {\n\t'content-Type': '" + transcontent + "'\n\t'Authorization': ''\n}\nresponse = requests.request('POST', url, headers = headers, data = payload, allow_redirects=False, timeout=None)\n" +
        "print(response.text)"
});
transactiontranscontent.push({
    key: 6, label: "PHP", value: 5, text: '<?php\n$curl = curl_init();\ncurl_setopt_array($curl, array(\n\tCURLOPT_URL => "' + localUrl + '/transaction",\n\tCURLOPT_RETURNTRANSFER => true,\n\tCURLOPT_ENCODING => "",\n' +
        '\tCURLOPT_MAXREDIRS => 10,\n\tCURLOPT_TIMEOUT => 0,\n\tCURLOPT_FOLLOWLOCATION => false,\n\tCURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n\tCURLOPT_CUSTOMREQUEST => "POST",\n' +
        '\tCURLOPT_POSTFIELDS => "' + transbody.replace(/\t/g, "\t\t") + '",\n\tCURLOPT_HTTPHEADER => array(\n\t\t"content-Type: ' + transcontent + '"\n\t\t"Authorization: "\n\t),\n));\n$response = curl_exec($curl);\n' +
        '$err = curl_error($curl);\ncurl_close($curl);\nif ($err) {\n\techo "cURL Error #:" . $err;\n} else {\n\techo $response;\n} ?>'
});
const transaction = {
    url: `${localUrl}/transaction`,
    headers: transcontent,
    body: transbody,
    tabs: transactiontranscontent
};

//Service Station Content
const serviceStationcontent = [];
serviceStationcontent.push({
    key: 1, label: "curl", value: 0, text: 'curl --location "\n--header "Authorization: "\n--request GET "' + localUrl + '/servicestation" \n--data ""'
});
serviceStationcontent.push({
    key: 2, label: "jQuery", value: 1, text: 'var settings = { \n\t"url": "' + localUrl + '/servicestation",\n\t"method": "GET",\n\t' +
        '"timeout": 0,\n\t"headers": { \n\t\t"Authorization": ""\n\t}\n};\n$.ajax(settings).done(function (response) {\n\tconsole.log(response);\n});'
});
// serviceStationcontent.push({
//     key: 3, label: "Node", value: 2, text: "var http = require('http');\nvar options = {\n\t'method': 'GET',\n\t'hostname': 'localhost',\n\t" +
//         "'path': '/v1/servicestation',\n\t'headers': {\n\t\t'Authorization': ''\n\t}\n};\nvar req = http.request(options, function (res) {\n\tvar chunks = [];\n\tres.on(\"data\", function (chunk) {\n\t\t" +
//         "chunks.push(chunk);\n\t});\n\tres.on(\"end\", function (chunk) {\n\t\tvar body = Buffer.concat(chunks);\n\t\tconsole.log(body.toString());\n\t});\n\t" +
//         "res.on(\"error\", function (error) {\n\t\tconsole.error(error);\n\t});\n});\nreq.end();"
// });

serviceStationcontent.push({
    key: 3, label: "Node", value: 2, text: "const rp = require('request-promise');" +
    "\nlet options = { "+
    "\n\tmethod: 'GET', "+
    "\n\turi: ' " + localUrl + "/servicestation',"+
    "\n\theaders: {"+
    "\n\t\t'Authorization': ''"+
    "\n\t},"+
    "\n}"+
    "\nrp(options)"+
    "\n\t.then(function (response) {"+
    "\n\t\tconsole.log(response);"+
    "\n\t}).catch(function (error) {"+
    "\n\t\tconsole.log(error);"+
    "\n});"
});

serviceStationcontent.push({
    key: 4, label: "Ruby", value: 3, text: 'require "uri"\nrequire "net/http"\nurl = URI("' + localUrl + '/servicestation")\n' +
        'http = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nrequest = Net::HTTP::Get.new(url)\nrequest["Authorization"] = ""\nresponse = http.request(request)\nputs response.read_body\n'
});
serviceStationcontent.push({
    key: 5, label: "Python", value: 4, text: "import requests\nurl = '" + localUrl + "/servicestation'\nheaders = {\n\t'Authorization': ''\n}\n" +
        "response = requests.request('GET', url, headers = headers, allow_redirects=False, timeout=None)\nprint(response.text)"
});
serviceStationcontent.push({
    key: 6, label: "PHP", value: 5, text: '<?php\n$curl = curl_init();\ncurl_setopt_array($curl, array(\n\tCURLOPT_URL => "' + localUrl + '/servicestation",\n\t' +
        'CURLOPT_RETURNTRANSFER => true,\n\tCURLOPT_ENCODING => "",\n\tCURLOPT_MAXREDIRS => 10,\n\tCURLOPT_TIMEOUT => 0,\n\tCURLOPT_FOLLOWLOCATION => false,\n\t' +
        'CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n\tCURLOPT_CUSTOMREQUEST => "GET",\n\tCURLOPT_HTTPHEADER => array(\n\t\t"Authorization: "\n\t)\n));\n$response = curl_exec($curl);\n$err = curl_error($curl);\n' +
        'curl_close($curl);\nif ($err) {\n\techo "cURL Error #:" . $err;\n} else {\n\techo $response;\n} ?>'
});
const serviceStation = {
    url: `${localUrl}/servicestation`,
    headers: '',
    body: '',
    tabs: serviceStationcontent
};

export default class Utilities {
    static transaction = `${localUrl}/transaction`;
    static transactionsandbox = `${localUrl}/sandbox/transaction`;
    static token = `${localUrl}/token`;
    static servicestationUrl = `${localUrl}/servicestation`;
    static servicestationUrlsandbox = `${localUrl}/sandbox/servicestation`;
    static transactionCard = transaction;
    static serviceStationCard = serviceStation;
}