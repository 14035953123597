
class ConnetToAPI {
    static getData = async (method, token, cb) => {
        const headers = new Headers();
        headers.append('Authorization', token);

        await fetch(method, { headers })
            .then(res => res.json())
            .then(async (res) => {
                cb(null, res);
            })
            .catch(async (error) => {
                console.log(`Error ejecutando fetch: ${error}`);
                cb(error, null);
            });
    }

    static sendData = async (method, data, token, cb) => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        await fetch(method, {
            headers,
            method: 'POST',
            timeout: 500,
            body: JSON.stringify(data),
        })
            .then((res) => {
                if (res.status === 204) {
                    return res.statusText;
                }
                return res.json();
            })
            .then(async (res) => {
                console.log('Success send data POST');
                cb(null, res);
            })
            .catch((error) => {
                console.log('Error ejecutando fetch' + error);
                cb(error, null);
            });
    }
}
export { ConnetToAPI };