import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LogoBac from '../src/imgs/Logo_BAC.png'
import LogoFlotas from '../src/imgs/Flota.png'
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  imgheight: {
      height: '6vh',      
  },
  footerLeft : {
    textAlign: 'left',
    height: '9vh',      
    width: '50%',
    marginLeft: 10,
  },
  footerRight : {
    textAlign: 'right',
    height: '8vh', 
    width: '50%',
    marginRight: 10,
  }, 
  footerContainer: {
    display: 'flex',
    background: 'white',
  } 
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: 0,
    };    
  }

  render(){
    const { classes } = this.props;

    return (    
        <div>
            <Divider />
            <div className={classes.footerContainer}>
                <div className={classes.footerLeft}>
                    <br />
                    <img src={LogoFlotas} alt="Logo" className={classes.imgheight} />                
                </div>
                <div className={classes.footerRight}>            
                    <br />
                    <img src={LogoBac} alt="Logo" className={classes.imgheight} />                
                </div>
            </div>                
        </div>       
    );
  }  
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);